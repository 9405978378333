/* Generated by restful-react */

import React from 'react'
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react'
export const SPEC_VERSION = '2.0.0'
/**
 * Información del usuario conectado
 */
export interface AuthInfo {
  userInfo: UserInfo
  /**
   * Publicaciones
   *
   */
  publications: Publication[]
  /**
   * Documentos
   */
  documents: Document[]
}

/**
 * Documentos de referencia para la entidad
 */
export interface Document {
  /**
   * Codigo
   */
  id: number
  /**
   * Título del documento
   */
  title: string
  /**
   * Enlace para descargar el documento
   */
  url: string
}

/**
 * Publicaciones de referencia para una sede
 */
export interface Publication {
  /**
   * Codigo
   */
  id: number
  /**
   * Título corto
   */
  title: string
  /**
   * Texto de la publicación
   */
  content: string
  /**
   * Fecha de publicación
   */
  date: string
  /**
   * Usuario quien crea la publicación
   */
  creator: string
}

/**
 * Mensaje de respuesta cuando existe error
 */
export interface APIError {
  /**
   * Mensaje de error
   */
  message_error?: string
  /**
   * Codigo de error
   */
  error_code?: number
}

/**
 * Información de la empresa
 */
export interface EntitiesModel {
  /**
   * Identificador
   */
  id: number
  /**
   * Sedes
   */
  locations: BranchOffice[]
  name: string
  /**
   * indica si la entida usa CAPTCHA
   */
  useCaptcha: boolean
}

/**
 * Correo del usuario
 */
export interface UserNotificationEmail {
  email?: string
}

/**
 * Correo del usuario
 */
export interface UserEmail {
  id?: number
  email?: string
}

/**
 * Correo del usuario
 */
export interface EmailResponse {
  email?: string
}

/**
 * Información del usuario conectado
 */
export interface UserInfo {
  /**
   * Nombre completo
   */
  name: string
  /**
   * Correo
   */
  email: string
  /**
   * Compañia
   */
  company: string
  /**
   * Ambiente donde está desplegado el servicio
   */
  environment: string
  /**
   * Versión de la solución
   */
  version: string
  /**
   * Base de datos
   */
  database: string
  /**
   * Enlace con el contenido de la imagen de perfil del usuario
   */
  avatar: string
  /**
   * Surcusal o sede
   */
  branchOffice: string
  /**
   * Nombre corto de la compañia
   */
  shortCompanyName: string
  /**
   * tiempo de expiración de la session cuando el usuario esta inactivo
   */
  idleTimeout: number
}

/**
 * Opciones del menú
 */
export interface MenuItem {
  /**
   * Identificador de la opción del menú
   */
  id: number
  /**
   * Título a desplegar
   */
  title: string
  /**
   * Dirección del enlace a la página
   */
  url: string
  items?: MenuItem[]
}

/**
 * Opciones del menú
 */
export interface MenuItems {
  /**
   * Opciones del menú
   */
  d: MenuItem[]
}

/**
 * Objeto vacio
 */
export interface EmptyObject {
  /**
   * Identificador
   */
  id?: number
}

/**
 * Respuesta de la validación de la sesión
 */
export interface SessionResponse {
  /**
   * Sesión expirada
   */
  ExpiredSession: boolean
}

/**
 * Respuesta de la validación de la sesión
 */
export interface PingSessionResponse {
  d: SessionResponse
}

/**
 * Información para el cambio de clave.
 */
export interface ChangePassword {
  /**
   * Clave actual
   */
  txtPwdAnterior: string
  /**
   * Nueva clave
   */
  txtNPwd: string
}

/**
 * Respuesta del cambio de clave.
 */
export interface ChangePasswordResponse {
  /**
   * Respuesta del proceso
   */
  d: string
}

/**
 * Sede de la compañia
 */
export interface BranchOffice {
  /**
   * Identificador
   */
  id: number
  /**
   * Nombre de la sede
   */
  name: string
}

/**
 * Objeto que muestra el detalle de la notificación.
 */
export interface Notification {
  /**
   * Mensaje a mostrar en las notificaciones.
   */
  message: string
  /**
   * Sólo tendrá 2 tipos, "Normal" y "Important", los "Important" se resaltaran en un snackbar.
   */
  type: string
  /**
   * Titulo de la notificación
   */
  title: string
}

/**
 * Lista de notificaciones asociadas a un usuario.
 */
export type NotificationsList = Notification[]

/**
 * Validación individual de salud
 */
export interface HealthStep {
  /**
   * Nombre
   */
  step_name: string
  /**
   * Información adicional
   */
  additional_information?: string
  /**
   * Considerado Crítico
   */
  is_critical: boolean
  /**
   * Pasos dependientes
   */
  dependent_step_responses?: HealthStep[]
  /**
   * Tiempo de la validación
   */
  latency_seconds: number
  /**
   * Representa el estado de la verificación de salud.
   *
   * Los valores de esta enumeración se ordenan de menos saludable a más saludable.
   * Degradado es mayor que insalubre pero menos que saludable.
   *
   * Valores permitidos:
   *
   * Indica que la verificación de estado determinó que el componente no estaba en buen estado o que se lanzó una excepción no controlada al ejecutar la verificación de estado.
   *
   *          Unhealthy = 0
   *
   * Indica que la verificación de estado determinó que el componente estaba en un estado degradado. Ejemplo: Puede brindar servicio pero no con tiempos optimos.
   *
   *          Degraded = 1
   *
   * Indica que la verificación de estado determinó que el componente estaba en buen estado.
   *
   *         Healthy = 2
   */
  status: number
}

/**
 * Resultado de la validación del estado de salud
 */
export interface HealthCheck {
  /**
   * Versión
   */
  service_version?: string
  steps: HealthStep[]
  /**
   * Representa el estado de la verificación de salud.
   *
   * Los valores de esta enumeración se ordenan de menos saludable a más saludable.
   * Degradado es mayor que insalubre pero menos que saludable.
   *
   * Valores permitidos:
   *
   * Indica que la verificación de estado determinó que el componente no estaba en buen estado o que se lanzó una excepción no controlada al ejecutar la verificación de estado.
   *
   *          Unhealthy = 0
   *
   * Indica que la verificación de estado determinó que el componente estaba en un estado degradado. Ejemplo: Puede brindar servicio pero no con tiempos optimos.
   *
   *          Degraded = 1
   *
   * Indica que la verificación de estado determinó que el componente estaba en buen estado.
   *
   *         Healthy = 2
   */
  status: number
}

/**
 * Clave de acceso y de refresco en formato JWT.
 */
export interface SecurityTokens {
  /**
   * Token de acceso
   */
  access_token?: string
  /**
   * Token de refresco
   */
  refresh_token?: string
}

/**
 * Token de Refresco en formato JWT
 */
export interface RefreshToken {
  /**
   * Token de refresco
   */
  refresh_token?: string
}

/**
 * Profesional del servicio de salud que atiende pacientes.
 */
export interface Professional {
  /**
   * Identificador del profesional
   */
  id: number
  /**
   * Nombre del profesional
   */
  first_name: string
  /**
   * segundo nombre
   */
  second_name?: string
  /**
   * primer apellido
   */
  last_name: string
  /**
   * segundo apellido.
   */
  second_last_name?: string
  /**
   * nombre completo del profesional
   */
  full_name: string
}

/**
 * Ubicaciones dentro de las sedes. Consultorios o salas especializadas.
 */
export interface Room {
  id?: number
  name?: string
  /**
   * id de grupo de ubicaciones
   */
  room_group_id?: number
}

/**
 * Creación de una agenda.
 */
export interface ScheduleInput {
  slot_duration_in_min: number
  extra_slots_count?: number
  observations?: string
  professional_id: number
  room_id: number
  /**
   * Identificadores de los exámenes
   *
   * - Recibe multiples examenes por defecto.
   * - En caso de enviar un examen grupal solamente se recibira un unico examen.
   */
  exam_ids: number[]
  /**
   * fecha de activación.
   */
  activation_date: string
  /**
   * Días de la semana donde tendrá espacios disponibles para esta agenda. Contiene un arreglo de enteros (enum) que representan los 7 días de la semana: [lunes = 1, martes = 2, miercoles=3, jueves=4, viernes=5, sabado=6, domingo=7]
   */
  days_of_week: number[]
  /**
   * Fecha inicial de la agenda. Usa el formato de fecha basado en la ISO 8601. (YYYY-MM-DD)
   */
  start_date: string
  /**
   * Fecha final de la agenda. Usa el formato de fecha basado en la ISO 8601. (YYYY-MM-DD)
   */
  end_date: string
  /**
   * Arreglo que incluye las franjas de fechas que se desean crear.
   */
  hours: ScheduleInputHours[]
  /**
   * Cantidad de pacientes permitida.
   * - Este campo no es requerido y será ignorado por defecto.
   * - Este campo es requerido si se envía exámenes grupales.
   * - Los valores enviados deben ser mayores a 0.
   */
  quantity_patients?: number
  /**
   * Permite habilitar los espacios de la agenda para citas web desde la creación de la agenda. siempre y cuando los examenes asociados esten habilitados para citas web.
   */
  web_appointment?: boolean
}

/**
 * Estas son las observaciones usadas para cambiar, reservar o eliminar un espacio de la agenda.
 */
export interface ScheduleSlotObservation {
  /**
   * Numero consecutivo que se utiliza para identificar la observacion en la base de datos.
   */
  id: number
  /**
   * Valor de la observacion que se asigna al espacio de la agenda.
   */
  observation: string
}

/**
 * Fechas de los rangos de las franjas que se desean crear.
 */
export interface ScheduleInputHours {
  /**
   * Fecha inicial de la agenda. Usa el formato de fecha basado en la ISO 8601. (Thh:mm:ss)
   */
  start_hour: string
  /**
   * Fecha final de la agenda. Usa el formato de fecha basado en la ISO 8601. (Thh:mm:ss)
   */
  end_hour: string
}

/**
 * Examen, especialidad o procedimiento médico.
 */
export interface Exam {
  /**
   * Identificador
   */
  id?: number
  /**
   * Nombre del examen
   */
  name?: string
  /**
   * Si está o no habilitado para cita web
   */
  web_appointment?: boolean
  /**
   * Si está o no habilitado para cita grupal
   */
  group_appointment?: boolean
}

/**
 * Espacios de tiempo dentro de una agenda donde se puede agendar una cita.
 */
export interface Slot {
  /**
   * identificador del espacio.
   */
  id: number
  /**
   * fecha del espacio de la agenda. Usa formato (YYYY-MM-DDThh:mm:ss-hh:mm)
   */
  date: string
  /**
   * duración en minutos del espacio
   */
  duration_in_min: number
  /**
   * si el espacio está habilitado o no.
   */
  enabled: boolean
  /**
   * si el espacio ya tiene una cita asignada.
   */
  is_assigned: boolean
  /**
   * si el espacio está reservado.
   */
  is_reserved: boolean
  /**
   * Si el espacio está habilitado para cita web.
   */
  web_appointment?: boolean
  /**
   * Identificación de la observación para los cambios de estado del espacio de agenda.
   */
  observation_id?: number
}

/**
 * Agenda médica.
 */
export interface Schedule {
  /**
   * Consecutivo
   */
  id: number
  /**
   * Fecha inicial de la agenda. Usa el formato de fecha basado en la ISO 8601. (YYYY-MM-DDThh:mm:ss+hh:mm)
   */
  start_date: string
  /**
   * Fecha final de la agenda. Usa el formato de fecha basado en la ISO 8601. (YYYY-MM-DDThh:mm:ss+hh:mm)
   */
  end_date: string
  /**
   * Duración de cada uno de los espacios de esta agenda.
   */
  slot_duration_in_min: number
  /**
   * Número de espacios extras que deben ser reservados en la agenda.
   */
  extra_slots_count: number
  /**
   * Observaciones adicionales sobre esta agenda.
   */
  observations?: string
  room: Room
  /**
   * Fecha de activación
   */
  activation_date: string
  /**
   * lista de los espacios disponibles dentro de esta agenda.
   */
  slots: Slot[]
  /**
   * Examenes, especialidades o procedimientos médico que es agendado.
   */
  exams: Exam[]
  /**
   * Días de la semana
   */
  days_of_week?: number[]
  /**
   * Cantidad de pacientes para citas grupales
   */
  patient_quantity?: number
  /**
   * Nombre del usuario que creo la agenda.
   */
  creator_name?: string
  /**
   * Fecha de creación.
   */
  creation_date: string
  /**
   * Identificador del usuario que creo la agenda.
   */
  creator_id: number
}

/**
 * Actualización de una agenda.
 */
export interface ScheduleInputUpdate {
  /**
   * Número de espacios extras que deben ser reservados en la agenda.
   */
  extra_slots_count: number
}

/**
 * Respuesta para la generación del key en QR para que los usuarios puedan agregar el factor de autenticación en la aplicación de Google Auth
 */
export interface GoogleAuthGenerate {
  /**
   * Imagen en base 64 de QR con la key para ser agregado a la aplicación de Google Auth para realizar su posterior autenticación.
   */
  QrCode: string
  /**
   * Código para ser agregado a la aplicación de Google Auth
   */
  Code?: string
  /**
   * Pista de correo electrónico
   */
  MaskEmail?: string
}

export interface GoogleAuthValidate {
  /**
   * Verifica la auntenticidad de un código generado en la aplicación de google Auth.
   */
  validate: boolean
}

export interface RequestGoogleAuthValidate {
  /**
   * código de que es generado por la app Google Auth para validar al usuario
   */
  code: string
  /**
   * indicador para el primer ingreso del usuario usando el segundo factor de autenticación
   */
  firstLogin?: boolean
}

/**
 * token para evitar el "Cross-Site Request Forgery" (falsificación de petición en sitios cruzados)
 */
export interface CSRFToken {
  csrfToken: string
}

/**
 * Información necesaria para crear una sesión de usuario
 */
export interface LoginModelToken {
  /**
   * Token de Login
   */
  tokenLogin: string
}

/**
 * datos para selecionar la entidad y sede
 */
export interface SelectEntityModel {
  /**
   * Empresa
   */
  entity_id: number
  /**
   * Sede
   */
  location_id: number
}

/**
 * Información necesaria para crear una sesión de usuario
 */
export interface LoginModel {
  /**
   * Nombre de usuario
   */
  username: string
  /**
   * Clave
   */
  password: string
}

/**
 * configuració de contraseña del ambiente
 */
export interface PasswordConfigResponse {
  minPasswordLen: number
  maxPasswordLen: number
  validChars: string
}

export interface RequestChangePassword {
  passwordRecoverytoken: string
  newPassword: string
}

export type GetAuthInfoProps = Omit<GetProps<AuthInfo, void, void, void>, 'path'>

/**
 * Obtener información del usuario conectado
 *
 * - Información del usuario (nombre, correo, avatar)
 * - Publicaciones de la clínica
 * - Documentos a tener en cuenta
 */
export const GetAuthInfo = (props: GetAuthInfoProps) => (
  <Get<AuthInfo, void, void, void> path={`/api/v1/auth/info`} {...props} />
)

export type UseGetAuthInfoProps = Omit<UseGetProps<AuthInfo, void, void, void>, 'path'>

/**
 * Obtener información del usuario conectado
 *
 * - Información del usuario (nombre, correo, avatar)
 * - Publicaciones de la clínica
 * - Documentos a tener en cuenta
 */
export const useGetAuthInfo = (props: UseGetAuthInfoProps) =>
  useGet<AuthInfo, void, void, void>(`/api/v1/auth/info`, props)

export type PutCurrentUserConfigProps = Omit<
  MutateProps<void, APIError | void, void, UserNotificationEmail, void>,
  'path' | 'verb'
>

/**
 * Actualizar la configuración del usuario conectado
 *
 * Actualiza la configuración de notificaciones
 */
export const PutCurrentUserConfig = (props: PutCurrentUserConfigProps) => (
  <Mutate<void, APIError | void, void, UserNotificationEmail, void>
    verb='PUT'
    path={`/api/v1/current_user/config`}
    {...props}
  />
)

export type UsePutCurrentUserConfigProps = Omit<
  UseMutateProps<void, APIError | void, void, UserNotificationEmail, void>,
  'path' | 'verb'
>

/**
 * Actualizar la configuración del usuario conectado
 *
 * Actualiza la configuración de notificaciones
 */
export const usePutCurrentUserConfig = (props: UsePutCurrentUserConfigProps) =>
  useMutate<void, APIError | void, void, UserNotificationEmail, void>('PUT', `/api/v1/current_user/config`, props)

export type PutAuthProps = Omit<MutateProps<void, APIError, void, void, void>, 'path' | 'verb'>

/**
 * Crea una nueva sesión basado en un token de acceso
 *
 * Para evitar realizar un login cada vez que se abre un nueva pestaña en el navegador este servicio puede crear una sesión confiando en el access token previamente obtenido en el login del aplicativo.
 */
export const PutAuth = (props: PutAuthProps) => (
  <Mutate<void, APIError, void, void, void> verb='PUT' path={`/api/v1/auth`} {...props} />
)

export type UsePutAuthProps = Omit<UseMutateProps<void, APIError, void, void, void>, 'path' | 'verb'>

/**
 * Crea una nueva sesión basado en un token de acceso
 *
 * Para evitar realizar un login cada vez que se abre un nueva pestaña en el navegador este servicio puede crear una sesión confiando en el access token previamente obtenido en el login del aplicativo.
 */
export const usePutAuth = (props: UsePutAuthProps) =>
  useMutate<void, APIError, void, void, void>('PUT', `/api/v1/auth`, props)

export type PostAuthProps = Omit<MutateProps<SecurityTokens, APIError, void, LoginModel, void>, 'path' | 'verb'>

/**
 * Login del sistema
 *
 * Crea una sesión usando el usuario, clave, empresa y sede.
 *
 * Retornando (JWT) un access token y un refresh token.
 *
 * JSON Web Token (JWT) es un estandar abierto basado en JSON para crear tokens de acceso que permiten el uso de recursos de una aplicación o API. Este token llevará incorporada la información del usuario que necesita el servidor para identificarlo, así como información adicional que pueda serle útil (roles, permisos, etc.).
 *
 * Tipos de token
 *
 * Hay muchos tipos de token, aunque en la autenticación con JWT los más típicos son el access token y el refresh token.
 *
 * - Access token: Lleva contenida toda la información que necesita el servidor para saber si el usuario / dispositivo puede acceder al recurso que está solicitando o no. Suelen ser tokens caducos con un periodo de validez corto.
 *
 * - Refresh token: El refresh token es usado para generar un nuevo access token. Típicamente, si el access token tiene fecha de expiración, una vez que caduca, el usuario tendría que autenticarse de nuevo para obtener un access token. Con el refresh token, este paso se puede saltar y con una petición al API obtener un nuevo access token que permita al usuario seguir accediendo a los recursos de la aplicación.
 *
 */
export const PostAuth = (props: PostAuthProps) => (
  <Mutate<SecurityTokens, APIError, void, LoginModel, void> verb='POST' path={`/api/v1/auth`} {...props} />
)

export type UsePostAuthProps = Omit<UseMutateProps<SecurityTokens, APIError, void, LoginModel, void>, 'path' | 'verb'>

/**
 * Login del sistema
 *
 * Crea una sesión usando el usuario, clave, empresa y sede.
 *
 * Retornando (JWT) un access token y un refresh token.
 *
 * JSON Web Token (JWT) es un estandar abierto basado en JSON para crear tokens de acceso que permiten el uso de recursos de una aplicación o API. Este token llevará incorporada la información del usuario que necesita el servidor para identificarlo, así como información adicional que pueda serle útil (roles, permisos, etc.).
 *
 * Tipos de token
 *
 * Hay muchos tipos de token, aunque en la autenticación con JWT los más típicos son el access token y el refresh token.
 *
 * - Access token: Lleva contenida toda la información que necesita el servidor para saber si el usuario / dispositivo puede acceder al recurso que está solicitando o no. Suelen ser tokens caducos con un periodo de validez corto.
 *
 * - Refresh token: El refresh token es usado para generar un nuevo access token. Típicamente, si el access token tiene fecha de expiración, una vez que caduca, el usuario tendría que autenticarse de nuevo para obtener un access token. Con el refresh token, este paso se puede saltar y con una petición al API obtener un nuevo access token que permita al usuario seguir accediendo a los recursos de la aplicación.
 *
 */
export const usePostAuth = (props: UsePostAuthProps) =>
  useMutate<SecurityTokens, APIError, void, LoginModel, void>('POST', `/api/v1/auth`, props)

export interface PostUsersPasswordPathParams {
  /**
   * Nombre del usuario
   */
  username: string
}

export type PostUsersPasswordProps = Omit<
  MutateProps<void, APIError, void, void, PostUsersPasswordPathParams>,
  'path' | 'verb'
> &
  PostUsersPasswordPathParams

/**
 * Recuperación de contraseña
 *
 * Recuperación de contraseña
 */
export const PostUsersPassword = ({ username, ...props }: PostUsersPasswordProps) => (
  <Mutate<void, APIError, void, void, PostUsersPasswordPathParams>
    verb='POST'
    path={`/api/v1/users/${username}/password`}
    {...props}
  />
)

export type UsePostUsersPasswordProps = Omit<
  UseMutateProps<void, APIError, void, void, PostUsersPasswordPathParams>,
  'path' | 'verb'
> &
  PostUsersPasswordPathParams

/**
 * Recuperación de contraseña
 *
 * Recuperación de contraseña
 */
export const usePostUsersPassword = ({ username, ...props }: UsePostUsersPasswordProps) =>
  useMutate<void, APIError, void, void, PostUsersPasswordPathParams>(
    'POST',
    (paramsInPath: PostUsersPasswordPathParams) => `/api/v1/users/${paramsInPath.username}/password`,
    { pathParams: { username }, ...props }
  )

export type PostGetMenuProps = Omit<MutateProps<MenuItems, APIError, void, EmptyObject, void>, 'path' | 'verb'>

/**
 * Obtener opciones del menú
 *
 * Obtiene todas las opciones del menú a las que el usuario tiene acceso.
 */
export const PostGetMenu = (props: PostGetMenuProps) => (
  <Mutate<MenuItems, APIError, void, EmptyObject, void>
    verb='POST'
    path={`/Services/UtilService.asmx/GetMenu`}
    {...props}
  />
)

export type UsePostGetMenuProps = Omit<UseMutateProps<MenuItems, APIError, void, EmptyObject, void>, 'path' | 'verb'>

/**
 * Obtener opciones del menú
 *
 * Obtiene todas las opciones del menú a las que el usuario tiene acceso.
 */
export const usePostGetMenu = (props: UsePostGetMenuProps) =>
  useMutate<MenuItems, APIError, void, EmptyObject, void>('POST', `/Services/UtilService.asmx/GetMenu`, props)

export interface PostPingSessionQueryParams {
  /**
   * Identificador de la sesión actual
   */
  iMedicalCloudAppTabNavId: string
}

export type PostPingSessionProps = Omit<
  MutateProps<PingSessionResponse, APIError, PostPingSessionQueryParams, EmptyObject, void>,
  'path' | 'verb'
>

/**
 * Validar estado de la sesión
 *
 * Validar estado de la sesión enviado en el parámetro.
 */
export const PostPingSession = (props: PostPingSessionProps) => (
  <Mutate<PingSessionResponse, APIError, PostPingSessionQueryParams, EmptyObject, void>
    verb='POST'
    path={`/Services/UtilService.asmx/PingSession`}
    {...props}
  />
)

export type UsePostPingSessionProps = Omit<
  UseMutateProps<PingSessionResponse, APIError, PostPingSessionQueryParams, EmptyObject, void>,
  'path' | 'verb'
>

/**
 * Validar estado de la sesión
 *
 * Validar estado de la sesión enviado en el parámetro.
 */
export const usePostPingSession = (props: UsePostPingSessionProps) =>
  useMutate<PingSessionResponse, APIError, PostPingSessionQueryParams, EmptyObject, void>(
    'POST',
    `/Services/UtilService.asmx/PingSession`,
    props
  )

export type PostChangePassProps = Omit<
  MutateProps<ChangePasswordResponse, unknown, void, ChangePassword, void>,
  'path' | 'verb'
>

/**
 * Cambio de clave
 *
 * Cambio de clave del usuario.
 */
export const PostChangePass = (props: PostChangePassProps) => (
  <Mutate<ChangePasswordResponse, unknown, void, ChangePassword, void>
    verb='POST'
    path={`/Services/changePass.asmx/changePWD`}
    {...props}
  />
)

export type UsePostChangePassProps = Omit<
  UseMutateProps<ChangePasswordResponse, unknown, void, ChangePassword, void>,
  'path' | 'verb'
>

/**
 * Cambio de clave
 *
 * Cambio de clave del usuario.
 */
export const usePostChangePass = (props: UsePostChangePassProps) =>
  useMutate<ChangePasswordResponse, unknown, void, ChangePassword, void>(
    'POST',
    `/Services/changePass.asmx/changePWD`,
    props
  )

export type GetAuthNotificationsProps = Omit<GetProps<NotificationsList, APIError, void, void>, 'path'>

/**
 * Listar notificaciones asociadas al usuario
 *
 * Permite listar todas las notificaciones asociadas a un usuario.
 */
export const GetAuthNotifications = (props: GetAuthNotificationsProps) => (
  <Get<NotificationsList, APIError, void, void> path={`/api/v1/auth/notifications`} {...props} />
)

export type UseGetAuthNotificationsProps = Omit<UseGetProps<NotificationsList, APIError, void, void>, 'path'>

/**
 * Listar notificaciones asociadas al usuario
 *
 * Permite listar todas las notificaciones asociadas a un usuario.
 */
export const useGetAuthNotifications = (props: UseGetAuthNotificationsProps) =>
  useGet<NotificationsList, APIError, void, void>(`/api/v1/auth/notifications`, props)

export interface GetHealthzQueryParams {
  /**
   * Token de validación de seguridad
   */
  token: string
  /**
   * Incluir o no los componentes que no son criticos en la respuesta de salud
   */
  onlyCritical?: boolean
}

export type GetHealthzProps = Omit<GetProps<HealthCheck, APIError | HealthCheck, GetHealthzQueryParams, void>, 'path'>

/**
 * Obtener estado de salud de la aplicación iMedicalCloud
 *
 * Realiza validaciones sobre el estado de salud en:
 * * Validar configuración de la base de datos, el servidor y la base de datos debe ser igual en todos los string de conexión.
 * * Conexion de base de datos.
 * * Conexion de base de datos de sesión.
 * * Servidor de reportes: usuario y clave debe ser validos.
 */
export const GetHealthz = (props: GetHealthzProps) => (
  <Get<HealthCheck, APIError | HealthCheck, GetHealthzQueryParams, void> path={`/api/v1/healthz`} {...props} />
)

export type UseGetHealthzProps = Omit<
  UseGetProps<HealthCheck, APIError | HealthCheck, GetHealthzQueryParams, void>,
  'path'
>

/**
 * Obtener estado de salud de la aplicación iMedicalCloud
 *
 * Realiza validaciones sobre el estado de salud en:
 * * Validar configuración de la base de datos, el servidor y la base de datos debe ser igual en todos los string de conexión.
 * * Conexion de base de datos.
 * * Conexion de base de datos de sesión.
 * * Servidor de reportes: usuario y clave debe ser validos.
 */
export const useGetHealthz = (props: UseGetHealthzProps) =>
  useGet<HealthCheck, APIError | HealthCheck, GetHealthzQueryParams, void>(`/api/v1/healthz`, props)

export type PutTokenProps = Omit<MutateProps<SecurityTokens, APIError, void, RefreshToken, void>, 'path' | 'verb'>

/**
 * Refrescar access y refresh token
 *
 * El refresh token requiere una seguridad mayor a la hora de ser almacenado que el access token, ya que si fuera sustraido por terceras partes, podrían utilizarlo para obtener access tokens y acceder a los recursos protegidos de la aplicación. Para poder cortar un escenario como este, debe implementarse en el servidor algún sistema que permita invalidar un refresh token, además de establecer un tiempo de vida que obviamente debe ser más largo que el de los access tokens.
 *
 * Este servicio permite reducir el riesgo refrescando un access token previamente emitido. Los refresh tokens solo podrán ser usados una sola vez.
 */
export const PutToken = (props: PutTokenProps) => (
  <Mutate<SecurityTokens, APIError, void, RefreshToken, void> verb='PUT' path={`/api/v1/token`} {...props} />
)

export type UsePutTokenProps = Omit<UseMutateProps<SecurityTokens, APIError, void, RefreshToken, void>, 'path' | 'verb'>

/**
 * Refrescar access y refresh token
 *
 * El refresh token requiere una seguridad mayor a la hora de ser almacenado que el access token, ya que si fuera sustraido por terceras partes, podrían utilizarlo para obtener access tokens y acceder a los recursos protegidos de la aplicación. Para poder cortar un escenario como este, debe implementarse en el servidor algún sistema que permita invalidar un refresh token, además de establecer un tiempo de vida que obviamente debe ser más largo que el de los access tokens.
 *
 * Este servicio permite reducir el riesgo refrescando un access token previamente emitido. Los refresh tokens solo podrán ser usados una sola vez.
 */
export const usePutToken = (props: UsePutTokenProps) =>
  useMutate<SecurityTokens, APIError, void, RefreshToken, void>('PUT', `/api/v1/token`, props)

export type GetSchedulesProfessionalsProps = Omit<GetProps<Professional[], void, void, void>, 'path'>

/**
 * Obtiene los profesionales configurados para agendas médicas
 *
 * Obtiene los profesionales configurados para agendas médicas.
 *
 * - Los profesionales deben estar habilitados en el sistema.
 * - El médico debe tener exámenes asignados.
 */
export const GetSchedulesProfessionals = (props: GetSchedulesProfessionalsProps) => (
  <Get<Professional[], void, void, void> path={`/api/v2/schedules/professionals`} {...props} />
)

export type UseGetSchedulesProfessionalsProps = Omit<UseGetProps<Professional[], void, void, void>, 'path'>

/**
 * Obtiene los profesionales configurados para agendas médicas
 *
 * Obtiene los profesionales configurados para agendas médicas.
 *
 * - Los profesionales deben estar habilitados en el sistema.
 * - El médico debe tener exámenes asignados.
 */
export const useGetSchedulesProfessionals = (props: UseGetSchedulesProfessionalsProps) =>
  useGet<Professional[], void, void, void>(`/api/v2/schedules/professionals`, props)

export type GetSchedulesRoomsProps = Omit<GetProps<Room[], void, void, void>, 'path'>

/**
 * Obtiene las ubicaciones disponibles para agendar.
 *
 * Obtiene las ubicaciones disponibles para agendar.
 *
 * Las ubicaciones deben ser Consultarios y deben estar habilitadas.
 */
export const GetSchedulesRooms = (props: GetSchedulesRoomsProps) => (
  <Get<Room[], void, void, void> path={`/api/v2/schedules/rooms`} {...props} />
)

export type UseGetSchedulesRoomsProps = Omit<UseGetProps<Room[], void, void, void>, 'path'>

/**
 * Obtiene las ubicaciones disponibles para agendar.
 *
 * Obtiene las ubicaciones disponibles para agendar.
 *
 * Las ubicaciones deben ser Consultarios y deben estar habilitadas.
 */
export const useGetSchedulesRooms = (props: UseGetSchedulesRoomsProps) =>
  useGet<Room[], void, void, void>(`/api/v2/schedules/rooms`, props)

export type GetSchedulesSlotsObservationsProps = Omit<GetProps<ScheduleSlotObservation[], void, void, void>, 'path'>

/**
 * Obtiene las observaciones disponibles para los espacios de agenda, en un grupo empresarial.
 */
export const GetSchedulesSlotsObservations = (props: GetSchedulesSlotsObservationsProps) => (
  <Get<ScheduleSlotObservation[], void, void, void> path={`/api/v2/schedules/slots/observations`} {...props} />
)

export type UseGetSchedulesSlotsObservationsProps = Omit<
  UseGetProps<ScheduleSlotObservation[], void, void, void>,
  'path'
>

/**
 * Obtiene las observaciones disponibles para los espacios de agenda, en un grupo empresarial.
 */
export const useGetSchedulesSlotsObservations = (props: UseGetSchedulesSlotsObservationsProps) =>
  useGet<ScheduleSlotObservation[], void, void, void>(`/api/v2/schedules/slots/observations`, props)

export interface PutSchedulesSlotsPathParams {
  /**
   * Identificador de la agenda.
   */
  id: number
}

export type PutSchedulesSlotsProps = Omit<
  MutateProps<Slot[], APIError | void, void, Slot[], PutSchedulesSlotsPathParams>,
  'path' | 'verb'
> &
  PutSchedulesSlotsPathParams

/**
 * Modificar un espacio dentro de una agenda.
 */
export const PutSchedulesSlots = ({ id, ...props }: PutSchedulesSlotsProps) => (
  <Mutate<Slot[], APIError | void, void, Slot[], PutSchedulesSlotsPathParams>
    verb='PUT'
    path={`/api/v2/schedules/${id}/slots`}
    {...props}
  />
)

export type UsePutSchedulesSlotsProps = Omit<
  UseMutateProps<Slot[], APIError | void, void, Slot[], PutSchedulesSlotsPathParams>,
  'path' | 'verb'
> &
  PutSchedulesSlotsPathParams

/**
 * Modificar un espacio dentro de una agenda.
 */
export const usePutSchedulesSlots = ({ id, ...props }: UsePutSchedulesSlotsProps) =>
  useMutate<Slot[], APIError | void, void, Slot[], PutSchedulesSlotsPathParams>(
    'PUT',
    (paramsInPath: PutSchedulesSlotsPathParams) => `/api/v2/schedules/${paramsInPath.id}/slots`,
    { pathParams: { id }, ...props }
  )

export interface DeleteSchedulesSlotsMultipleQueryParams {
  /**
   * Indentificaciones de resevas de espacio separados por coma.
   */
  ids: string
}

export interface DeleteSchedulesSlotsMultiplePathParams {
  /**
   * Identificador de la agenda.
   */
  id: number
}

export type DeleteSchedulesSlotsMultipleProps = Omit<
  MutateProps<
    void,
    APIError | void,
    DeleteSchedulesSlotsMultipleQueryParams,
    void,
    DeleteSchedulesSlotsMultiplePathParams
  >,
  'path' | 'verb'
> &
  DeleteSchedulesSlotsMultiplePathParams

/**
 * Eliminar un espacio dentro de una agenda.
 */
export const DeleteSchedulesSlotsMultiple = ({ id, ...props }: DeleteSchedulesSlotsMultipleProps) => (
  <Mutate<void, APIError | void, DeleteSchedulesSlotsMultipleQueryParams, void, DeleteSchedulesSlotsMultiplePathParams>
    verb='DELETE'
    path={`/api/v2/schedules/${id}/slots`}
    {...props}
  />
)

export type UseDeleteSchedulesSlotsMultipleProps = Omit<
  UseMutateProps<
    void,
    APIError | void,
    DeleteSchedulesSlotsMultipleQueryParams,
    void,
    DeleteSchedulesSlotsMultiplePathParams
  >,
  'path' | 'verb'
> &
  DeleteSchedulesSlotsMultiplePathParams

/**
 * Eliminar un espacio dentro de una agenda.
 */
export const useDeleteSchedulesSlotsMultiple = ({ id, ...props }: UseDeleteSchedulesSlotsMultipleProps) =>
  useMutate<
    void,
    APIError | void,
    DeleteSchedulesSlotsMultipleQueryParams,
    void,
    DeleteSchedulesSlotsMultiplePathParams
  >('DELETE', (paramsInPath: DeleteSchedulesSlotsMultiplePathParams) => `/api/v2/schedules/${paramsInPath.id}/slots`, {
    pathParams: { id },
    ...props
  })

export interface DeleteSchedulesSlotsPathParams {
  /**
   * Identificador de la agenda.
   */
  id: number
}

export type DeleteSchedulesSlotsProps = Omit<
  MutateProps<void, APIError | void, void, number, DeleteSchedulesSlotsPathParams>,
  'path' | 'verb'
> &
  DeleteSchedulesSlotsPathParams

/**
 * Eliminar un espacio dentro de una agenda.
 */
export const DeleteSchedulesSlots = ({ id, ...props }: DeleteSchedulesSlotsProps) => (
  <Mutate<void, APIError | void, void, number, DeleteSchedulesSlotsPathParams>
    verb='DELETE'
    path={`/api/v2/schedules/${id}/slots`}
    {...props}
  />
)

export type UseDeleteSchedulesSlotsProps = Omit<
  UseMutateProps<void, APIError | void, void, number, DeleteSchedulesSlotsPathParams>,
  'path' | 'verb'
> &
  DeleteSchedulesSlotsPathParams

/**
 * Eliminar un espacio dentro de una agenda.
 */
export const useDeleteSchedulesSlots = ({ id, ...props }: UseDeleteSchedulesSlotsProps) =>
  useMutate<void, APIError | void, void, number, DeleteSchedulesSlotsPathParams>(
    'DELETE',
    (paramsInPath: DeleteSchedulesSlotsPathParams) => `/api/v2/schedules/${paramsInPath.id}/slots`,
    { pathParams: { id }, ...props }
  )

export interface PutWebAppointmentPathParams {
  /**
   * Identificador del profesional.
   */
  id: number
  /**
   * Identificador de la agenda.
   */
  schedule_id: number
}

export type PutWebAppointmentProps = Omit<
  MutateProps<Slot[], APIError | void, void, Slot[], PutWebAppointmentPathParams>,
  'path' | 'verb'
> &
  PutWebAppointmentPathParams

/**
 * Habilitar espacios de la agenda para citas web.
 */
export const PutWebAppointment = ({ id, schedule_id, ...props }: PutWebAppointmentProps) => (
  <Mutate<Slot[], APIError | void, void, Slot[], PutWebAppointmentPathParams>
    verb='PUT'
    path={`/api/v2/professionals/${id}/schedules/${schedule_id}/slots`}
    {...props}
  />
)

export type UsePutWebAppointmentProps = Omit<
  UseMutateProps<Slot[], APIError | void, void, Slot[], PutWebAppointmentPathParams>,
  'path' | 'verb'
> &
  PutWebAppointmentPathParams

/**
 * Habilitar espacios de la agenda para citas web.
 */
export const usePutWebAppointment = ({ id, schedule_id, ...props }: UsePutWebAppointmentProps) =>
  useMutate<Slot[], APIError | void, void, Slot[], PutWebAppointmentPathParams>(
    'PUT',
    (paramsInPath: PutWebAppointmentPathParams) =>
      `/api/v2/professionals/${paramsInPath.id}/schedules/${paramsInPath.schedule_id}/slots`,
    { pathParams: { id, schedule_id }, ...props }
  )

export interface GetProfessionalExamsPathParams {
  /**
   * Identificador del profesional.
   */
  id: number
}

export type GetProfessionalExamsProps = Omit<GetProps<Exam[], void, void, GetProfessionalExamsPathParams>, 'path'> &
  GetProfessionalExamsPathParams

/**
 * Obtiene una lista de examenes, especialidades configurados para agendar asignados a un profesional.
 */
export const GetProfessionalExams = ({ id, ...props }: GetProfessionalExamsProps) => (
  <Get<Exam[], void, void, GetProfessionalExamsPathParams>
    path={`/api/v2/schedules/professionals/${id}/exams`}
    {...props}
  />
)

export type UseGetProfessionalExamsProps = Omit<
  UseGetProps<Exam[], void, void, GetProfessionalExamsPathParams>,
  'path'
> &
  GetProfessionalExamsPathParams

/**
 * Obtiene una lista de examenes, especialidades configurados para agendar asignados a un profesional.
 */
export const useGetProfessionalExams = ({ id, ...props }: UseGetProfessionalExamsProps) =>
  useGet<Exam[], void, void, GetProfessionalExamsPathParams>(
    (paramsInPath: GetProfessionalExamsPathParams) => `/api/v2/schedules/professionals/${paramsInPath.id}/exams`,
    { pathParams: { id }, ...props }
  )

export interface GetProfessionalsSchedulesQueryParams {
  /**
   * Fecha final de rango de fechas para filtrar. Usa formato (YYYY-MM-DDThh:mm:ss-hh:mm).
   */
  end_date: string
  /**
   * Fecha inicial de rango de fechas para filtrar. Usa formato (YYYY-MM-DDThh:mm:ss-hh:mm).
   */
  start_date: string
}

export interface GetProfessionalsSchedulesPathParams {
  /**
   * Identificador del profesional.
   */
  id: number
}

export type GetProfessionalsSchedulesProps = Omit<
  GetProps<Schedule[], unknown, GetProfessionalsSchedulesQueryParams, GetProfessionalsSchedulesPathParams>,
  'path'
> &
  GetProfessionalsSchedulesPathParams

/**
 * Obtiene las agendas de un profesional basado en el identificador del profesional.
 */
export const GetProfessionalsSchedules = ({ id, ...props }: GetProfessionalsSchedulesProps) => (
  <Get<Schedule[], unknown, GetProfessionalsSchedulesQueryParams, GetProfessionalsSchedulesPathParams>
    path={`/api/v2/professionals/${id}/schedules`}
    {...props}
  />
)

export type UseGetProfessionalsSchedulesProps = Omit<
  UseGetProps<Schedule[], unknown, GetProfessionalsSchedulesQueryParams, GetProfessionalsSchedulesPathParams>,
  'path'
> &
  GetProfessionalsSchedulesPathParams

/**
 * Obtiene las agendas de un profesional basado en el identificador del profesional.
 */
export const useGetProfessionalsSchedules = ({ id, ...props }: UseGetProfessionalsSchedulesProps) =>
  useGet<Schedule[], unknown, GetProfessionalsSchedulesQueryParams, GetProfessionalsSchedulesPathParams>(
    (paramsInPath: GetProfessionalsSchedulesPathParams) => `/api/v2/professionals/${paramsInPath.id}/schedules`,
    { pathParams: { id }, ...props }
  )

export interface PostProfessionalsSchedulesPathParams {
  /**
   * Identificador del profesional.
   */
  id: number
}

export type PostProfessionalsSchedulesProps = Omit<
  MutateProps<number[], APIError | void, void, ScheduleInput, PostProfessionalsSchedulesPathParams>,
  'path' | 'verb'
> &
  PostProfessionalsSchedulesPathParams

/**
 * Creación de agenda.
 *
 * Validaciones necesarias:
 * - Todos los campos son requeridos. (Menos Observaciones)
 * - El campo room_id no puede ser ser cero. (roomId > 0)
 * - El campo days_of_week array no puede estar vacio.
 * - El campo exam_ids array no puede estar vacio.
 * - El campo exam_ids en caso de seleccionar un examen grupal solo se admite 1 elemento.
 * - El campo hours array no puede estar vacio. (hours > 0 y hours <= 2)
 * - El campo quantity_patients solo es requerido si se envia un examen grupal (quantity_patients > 0).
 * - El campo web_appointment es requerido si todos los examenes seleccionados para la creación de agenda estan habilitados para citas web.
 */
export const PostProfessionalsSchedules = ({ id, ...props }: PostProfessionalsSchedulesProps) => (
  <Mutate<number[], APIError | void, void, ScheduleInput, PostProfessionalsSchedulesPathParams>
    verb='POST'
    path={`/api/v2/professionals/${id}/schedules`}
    {...props}
  />
)

export type UsePostProfessionalsSchedulesProps = Omit<
  UseMutateProps<number[], APIError | void, void, ScheduleInput, PostProfessionalsSchedulesPathParams>,
  'path' | 'verb'
> &
  PostProfessionalsSchedulesPathParams

/**
 * Creación de agenda.
 *
 * Validaciones necesarias:
 * - Todos los campos son requeridos. (Menos Observaciones)
 * - El campo room_id no puede ser ser cero. (roomId > 0)
 * - El campo days_of_week array no puede estar vacio.
 * - El campo exam_ids array no puede estar vacio.
 * - El campo exam_ids en caso de seleccionar un examen grupal solo se admite 1 elemento.
 * - El campo hours array no puede estar vacio. (hours > 0 y hours <= 2)
 * - El campo quantity_patients solo es requerido si se envia un examen grupal (quantity_patients > 0).
 * - El campo web_appointment es requerido si todos los examenes seleccionados para la creación de agenda estan habilitados para citas web.
 */
export const usePostProfessionalsSchedules = ({ id, ...props }: UsePostProfessionalsSchedulesProps) =>
  useMutate<number[], APIError | void, void, ScheduleInput, PostProfessionalsSchedulesPathParams>(
    'POST',
    (paramsInPath: PostProfessionalsSchedulesPathParams) => `/api/v2/professionals/${paramsInPath.id}/schedules`,
    { pathParams: { id }, ...props }
  )

export interface PutSchedulePathParams {
  /**
   * Identificador de la agenda.
   */
  id: number
}

export type PutScheduleProps = Omit<
  MutateProps<Schedule, unknown, void, ScheduleInputUpdate, PutSchedulePathParams>,
  'path' | 'verb'
> &
  PutSchedulePathParams

/**
 * Actualizar agenda mediante el identificador.
 */
export const PutSchedule = ({ id, ...props }: PutScheduleProps) => (
  <Mutate<Schedule, unknown, void, ScheduleInputUpdate, PutSchedulePathParams>
    verb='PUT'
    path={`/api/v2/schedules/${id}`}
    {...props}
  />
)

export type UsePutScheduleProps = Omit<
  UseMutateProps<Schedule, unknown, void, ScheduleInputUpdate, PutSchedulePathParams>,
  'path' | 'verb'
> &
  PutSchedulePathParams

/**
 * Actualizar agenda mediante el identificador.
 */
export const usePutSchedule = ({ id, ...props }: UsePutScheduleProps) =>
  useMutate<Schedule, unknown, void, ScheduleInputUpdate, PutSchedulePathParams>(
    'PUT',
    (paramsInPath: PutSchedulePathParams) => `/api/v2/schedules/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  )

export type DeleteSchedulesProps = Omit<MutateProps<void, APIError | void, void, number, void>, 'path' | 'verb'>

/**
 * Eliminar agenda mediante el identificador.
 */
export const DeleteSchedules = (props: DeleteSchedulesProps) => (
  <Mutate<void, APIError | void, void, number, void> verb='DELETE' path={`/api/v2/schedules`} {...props} />
)

export type UseDeleteSchedulesProps = Omit<UseMutateProps<void, APIError | void, void, number, void>, 'path' | 'verb'>

/**
 * Eliminar agenda mediante el identificador.
 */
export const useDeleteSchedules = (props: UseDeleteSchedulesProps) =>
  useMutate<void, APIError | void, void, number, void>('DELETE', `/api/v2/schedules`, { ...props })

export interface ValidatePrescripionQueryParams {
  /**
   * Token se genera a partir del ID de la fórmula médica y contiene una fecha de expiración de 30 días. Esto evita peticiones no válidas y de fórmulas vencidas.
   */
  token: string
}

export type ValidatePrescripionProps = Omit<GetProps<void, void, ValidatePrescripionQueryParams, void>, 'path'>

/**
 * Obtiene la fórmula médica en formato PDF o
 *
 * Obtiene la fórmula médica en formato PDF o un mensaje de error
 */
export const ValidatePrescripion = (props: ValidatePrescripionProps) => (
  <Get<void, void, ValidatePrescripionQueryParams, void> path={`/api/v1/prescription/public`} {...props} />
)

export type UseValidatePrescripionProps = Omit<UseGetProps<void, void, ValidatePrescripionQueryParams, void>, 'path'>

/**
 * Obtiene la fórmula médica en formato PDF o
 *
 * Obtiene la fórmula médica en formato PDF o un mensaje de error
 */
export const useValidatePrescripion = (props: UseValidatePrescripionProps) =>
  useGet<void, void, ValidatePrescripionQueryParams, void>(`/api/v1/prescription/public`, props)

export type GetValidatecodeTwoFactorAuthProps = Omit<
  MutateProps<SecurityTokens, APIError, void, RequestGoogleAuthValidate, void>,
  'path' | 'verb'
>

/**
 * Válida el código perteneciente a un usuario
 */
export const GetValidatecodeTwoFactorAuth = (props: GetValidatecodeTwoFactorAuthProps) => (
  <Mutate<SecurityTokens, APIError, void, RequestGoogleAuthValidate, void>
    verb='POST'
    path={`/api/v1/auth/2fa/validate`}
    {...props}
  />
)

export type UseGetValidatecodeTwoFactorAuthProps = Omit<
  UseMutateProps<SecurityTokens, APIError, void, RequestGoogleAuthValidate, void>,
  'path' | 'verb'
>

/**
 * Válida el código perteneciente a un usuario
 */
export const useGetValidatecodeTwoFactorAuth = (props: UseGetValidatecodeTwoFactorAuthProps) =>
  useMutate<SecurityTokens, APIError, void, RequestGoogleAuthValidate, void>('POST', `/api/v1/auth/2fa/validate`, props)

export type GetGenerateTwoFactorAuthProps = Omit<GetProps<GoogleAuthGenerate, APIError, void, void>, 'path'>

/**
 * Obtiene la imagen del QR para poderla agregar a la aplicación de google Auth
 */
export const GetGenerateTwoFactorAuth = (props: GetGenerateTwoFactorAuthProps) => (
  <Get<GoogleAuthGenerate, APIError, void, void> path={`/api/v1/auth/2fa/begin`} {...props} />
)

export type UseGetGenerateTwoFactorAuthProps = Omit<UseGetProps<GoogleAuthGenerate, APIError, void, void>, 'path'>

/**
 * Obtiene la imagen del QR para poderla agregar a la aplicación de google Auth
 */
export const useGetGenerateTwoFactorAuth = (props: UseGetGenerateTwoFactorAuthProps) =>
  useGet<GoogleAuthGenerate, APIError, void, void>(`/api/v1/auth/2fa/begin`, props)

export interface GetValidateEmailQueryParams {
  /**
   * correo del usuario para su validación
   */
  email: string
}

export type GetValidateEmailProps = Omit<GetProps<GoogleAuthValidate, void, GetValidateEmailQueryParams, void>, 'path'>

/**
 * Confirmar el correo del usuario para continuar con el segundo factor de autenticación
 */
export const GetValidateEmail = (props: GetValidateEmailProps) => (
  <Get<GoogleAuthValidate, void, GetValidateEmailQueryParams, void> path={`/api/v1/auth/validate/email`} {...props} />
)

export type UseGetValidateEmailProps = Omit<
  UseGetProps<GoogleAuthValidate, void, GetValidateEmailQueryParams, void>,
  'path'
>

/**
 * Confirmar el correo del usuario para continuar con el segundo factor de autenticación
 */
export const useGetValidateEmail = (props: UseGetValidateEmailProps) =>
  useGet<GoogleAuthValidate, void, GetValidateEmailQueryParams, void>(`/api/v1/auth/validate/email`, props)

export type GetCSRFTokenProps = Omit<GetProps<CSRFToken, void, void, void>, 'path'>

/**
 * obtiene el CSRF  Token
 */
export const GetCSRFToken = (props: GetCSRFTokenProps) => (
  <Get<CSRFToken, void, void, void> path={`/api/v1/auth/token/csrf`} {...props} />
)

export type UseGetCSRFTokenProps = Omit<UseGetProps<CSRFToken, void, void, void>, 'path'>

/**
 * obtiene el CSRF  Token
 */
export const useGetCSRFToken = (props: UseGetCSRFTokenProps) =>
  useGet<CSRFToken, void, void, void>(`/api/v1/auth/token/csrf`, props)

export type PostAuthEncriptProps = Omit<
  MutateProps<SecurityTokens, APIError, void, LoginModelToken, void>,
  'path' | 'verb'
>

/**
 * Login del sistema con datos encriptados
 */
export const PostAuthEncript = (props: PostAuthEncriptProps) => (
  <Mutate<SecurityTokens, APIError, void, LoginModelToken, void> verb='POST' path={`/api/v1/auth/token`} {...props} />
)

export type UsePostAuthEncriptProps = Omit<
  UseMutateProps<SecurityTokens, APIError, void, LoginModelToken, void>,
  'path' | 'verb'
>

/**
 * Login del sistema con datos encriptados
 */
export const usePostAuthEncript = (props: UsePostAuthEncriptProps) =>
  useMutate<SecurityTokens, APIError, void, LoginModelToken, void>('POST', `/api/v1/auth/token`, props)

export type SelectCompanieProps = Omit<
  MutateProps<SecurityTokens, APIError, void, SelectEntityModel, void>,
  'path' | 'verb'
>

export const SelectCompanie = (props: SelectCompanieProps) => (
  <Mutate<SecurityTokens, APIError, void, SelectEntityModel, void>
    verb='POST'
    path={`/api/v1/auth/companies`}
    {...props}
  />
)

export type UseSelectCompanieProps = Omit<
  UseMutateProps<SecurityTokens, APIError, void, SelectEntityModel, void>,
  'path' | 'verb'
>

export const useSelectCompanie = (props: UseSelectCompanieProps) =>
  useMutate<SecurityTokens, APIError, void, SelectEntityModel, void>('POST', `/api/v1/auth/companies`, props)

export type GetUsersMyCompaniesProps = Omit<GetProps<EntitiesModel[], APIError, void, void>, 'path'>

/**
 * Obtiene listado de entidades
 */
export const GetUsersMyCompanies = (props: GetUsersMyCompaniesProps) => (
  <Get<EntitiesModel[], APIError, void, void> path={`/api/v1/users/companies`} {...props} />
)

export type UseGetUsersMyCompaniesProps = Omit<UseGetProps<EntitiesModel[], APIError, void, void>, 'path'>

/**
 * Obtiene listado de entidades
 */
export const useGetUsersMyCompanies = (props: UseGetUsersMyCompaniesProps) =>
  useGet<EntitiesModel[], APIError, void, void>(`/api/v1/users/companies`, props)

export interface GetValidateRecoveryPasswordTokenQueryParams {
  /**
   * token de recuperación de contraseña
   */
  passwordRecoverytoken: string
}

export type GetValidateRecoveryPasswordTokenProps = Omit<
  GetProps<PasswordConfigResponse, void, GetValidateRecoveryPasswordTokenQueryParams, void>,
  'path'
>

export const GetValidateRecoveryPasswordToken = (props: GetValidateRecoveryPasswordTokenProps) => (
  <Get<PasswordConfigResponse, void, GetValidateRecoveryPasswordTokenQueryParams, void>
    path={`/api/v1/users/password`}
    {...props}
  />
)

export type UseGetValidateRecoveryPasswordTokenProps = Omit<
  UseGetProps<PasswordConfigResponse, void, GetValidateRecoveryPasswordTokenQueryParams, void>,
  'path'
>

export const useGetValidateRecoveryPasswordToken = (props: UseGetValidateRecoveryPasswordTokenProps) =>
  useGet<PasswordConfigResponse, void, GetValidateRecoveryPasswordTokenQueryParams, void>(
    `/api/v1/users/password`,
    props
  )

export type PostChangePasswordProps = Omit<
  MutateProps<void, APIError, void, RequestChangePassword, void>,
  'path' | 'verb'
>

export const PostChangePassword = (props: PostChangePasswordProps) => (
  <Mutate<void, APIError, void, RequestChangePassword, void> verb='POST' path={`/api/v1/users/password`} {...props} />
)

export type UsePostChangePasswordProps = Omit<
  UseMutateProps<void, APIError, void, RequestChangePassword, void>,
  'path' | 'verb'
>

export const usePostChangePassword = (props: UsePostChangePasswordProps) =>
  useMutate<void, APIError, void, RequestChangePassword, void>('POST', `/api/v1/users/password`, props)
