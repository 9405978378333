import React, { FunctionComponent, useState } from 'react'
import { ChangePassword as ChangePasswordModel, usePostChangePass } from '../../api/api'
import { useAPISecurity } from '../../security/APISecurityContext'
import ChangePasswordForm, { ChangePasswordFormProps } from './ChangePasswordForm'

type SetOpenFunction = (open: boolean) => void

export interface ChangePasswordProps {
  open: boolean
  setOpen: SetOpenFunction
}

export const useChangePassword = (props: ChangePasswordProps): ChangePasswordFormProps => {
  const [responseApi, setResponseApi] = useState('')
  const [responseApiError, setResponseApiError] = useState(false)
  const changePasswordApi = usePostChangePass({})
  const { setIsAuthenticated } = useAPISecurity()

  const handleOk = (currentPassword: string, newPassword: string) => {
    const changePassword: ChangePasswordModel = { txtPwdAnterior: currentPassword, txtNPwd: newPassword }
    changePasswordApi
      .mutate(changePassword)
      .then(value => {
        setResponseApi('')
        if (value && value.d) {
          setResponseApiError(false)
          setResponseApi(value.d)
        }
      })
      .catch(() => setResponseApiError(true))
  }

  const closeSession = () => {
    setIsAuthenticated(false)
  }

  return {
    open: props.open,
    setOpen: props.setOpen,
    closeSession,
    handleOk,
    changePassword: {
      response: responseApi,
      error: responseApiError,
      loading: changePasswordApi.loading
    }
  }
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = props => {
  const propsForm = useChangePassword(props)

  return <ChangePasswordForm {...propsForm} />
}

export default ChangePassword
